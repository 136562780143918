export default {
	name: "Video",
	created () {
		this.$nextTick(function () {

		});
	},
	mounted () {

	},
	data () {
		return {

		};
	},
	computed: {

	},
	watch: {

	},
	methods: {

	}
};